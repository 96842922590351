/* when time track at bottom appears then this class will be assigned to teams main container  */
.teams-main-container {
  height: calc(100% - 72px - 136px);
  overflow: auto;
}

.teams-main-container-without-bottom-time-track {
  height: calc(100% - 72px);
  overflow: auto;
}

.excel-btn {
  color: #3b98d1 !important;
  font-weight: normal !important;
}

.team-select {
  color: #3b98d1 !important;
}

.dash-text {
  position: relative;
  font-size: 20px;
  color: #3b98d1;
  margin-left: -20px;
}

.drop-icon {
  position: relative;
  margin-left: -20px;
}

.calander-icon {
  margin-bottom: 2%;
}

.custom-col {
  border: 1px solid #3b98d1;
  border-bottom: none;
  padding: 2% 10%;
  background-color: grey;
}

.user-profile-image-teams {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 3px;
}

.weekly-hours {
  font-weight: 600 !important;
}

.total-mobile {
  background-color: #165e71;
  color: white;
}

.mobile-teams {
  position: relative;
  margin-bottom: 13.5rem;
}

.mobile-grand-total {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100vw;
  /* height: 13.5rem; */
  background-color: white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

/* overflowing of cell data handling  */
.name-wrapper {
  text-wrap: wrap;
}

.job-wrapper {
  text-wrap: wrap !important;
}

.gdkAMt {
  white-space: unset !important;
}

.custom-row {
  text-wrap: wrap !important;
}

.break-word {
  text-wrap: wrap !important;
}

/* overflowing of cell data handling  */