.main-container {
  background-color: #ffffff;
  margin: 2% 2%;
  padding: 1% 2%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search-bar {
  padding: 0.5rem;
  background-color: #f4f4f4;
}
.search-input {
  padding-left: 40px;
}

.search-icon {
  position: absolute;
  width: 20px;
  top: 12px;
  left: 12px;
}

.status {
  color: #3b98d1;
  font-size: 11px;
}
.detail-icon {
  cursor: pointer;
}
.detail-icon-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .search-bar {
    background-color: white;
  }
  .search-input {
    border: 2px solid #57b0e7;
  }
}
