@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: Lato;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

/* Override bootstrap row for gutter effect */
.row {
  --bs-gutter-x: 0rem !important;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 2em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  z-index: 353455555545;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.app-container {
  padding: 24px 40px !important;
  background-color: #f5f5f5;
  height: calc(100% - 72px);
}

.app-container-with-time-track-at-bottom {
  padding: 24px 40px !important;
  background-color: #f5f5f5;
  height: calc(100% - 72px - 136px);
  overflow: auto;
}

/* Text styles */

.text-heading-primary {
  color: #373d3f;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.text-heading-secondary {
  color: #373d3f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.modal-heading {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.text-heading {
  font-family: Lato;
  color: #373d3f;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.text-label-heading {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.normal-text-primary {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.normal-text {
  text-align: center;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500;
  line-height: 22px;
}

.primary-text-color {
  color: #3b98d1 !important;
}

.color-standard {
  color: #f5f5f5 !important;
}

.color-white {
  color: #fff !important;
}

.color-label {
  color: #757575;
}

.heading-color {
  color: #373d3f !important;
}

.badge-color {
  color: #555f61 !important;
}

.text-label {
  color: #555f61;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.text-label-header {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-label-secondary {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.position-unset {
  position: unset !important;
}

.text-label-primary {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.modal-inner-wrapper {
  padding: 0px 60px;
}

.model-button {
  width: 162px;
  padding: 16px;
  border-radius: 4px;
}

.core-outline-button {
  border: 1px solid #373d3f;
  background-color: transparent !important;
}

.core-danger-button {
  background: #f32626;
  border: none;
}

.primary-button {
  padding: 0.625rem 1.5rem;
  border-radius: 0.25rem;
  background: #3b98d1;
  border: none;
}

.primary-button-outline {
  border-radius: 4px;
  padding: 0.625rem 1.5rem;
  border: 1px solid #3b98d1;
  background: #fff;
  color: #3b98d1;
  font-family: Lato;
  font-size: 14px;
}

.primary-button:disabled {
  opacity: 0.5;
}

.primary-button-text {
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer !important;
}

.tt-data-wrapper {
  padding: 16px 12px 12px 12px;
}

.tt-table-wrapper {
  border-radius: 4px;
  border: 1px solid #e7eaee;
  background: #f2f8fe;
}

.tt-table-header-wrapper {
  border-radius: 4px 4px 0px 0px;
  background: #3b98d1;
  padding: 14px 12px;
  color: #f5f5f5;
}

.filter-header-wrapper {
  border-radius: 4px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 8px 12px;
}

.time-entry-wrapper {
  border-radius: 4px;
  border: 1px solid #e7eaee;
  background: #f4f4f4;
}

/* for history mobile view  */
.history-time-entry-wrapper {
  border-radius: 4px;
  background: #ffffff;
  color: black;
}

.pagination-history {
  transform: translate(0px, 6px);
}

.history-time-entry-header-wrapper {
  border-radius: 4px 4px 0px 0px;
  background: #ffffff;
  margin-bottom: 0.5rem;
}

.history-time-entry-header-wrapper .text-label-primary {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.more-span {
  width: 62px;
  height: 28px;
  border-radius: 30px;
  padding: 4px, 8px, 4px, 8px;
  background-color: #e9e9e9;

  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  position: relative;
  cursor: pointer;
}

.additional-jobs {
  display: none;
  position: absolute;
  top: 22px;
  right: -23px;
  width: 132px;
  height: auto;
  background: #ffffff;
  text-wrap: wrap;
  box-shadow: 0px 4px 16px 0px #00000014;
  padding: 10px;
  z-index: 1;

  border-radius: 4px;
  gap: 12px;

  color: #555f61;

  font-family: Lato;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.additional-jobs-sub {
  width: 112px;
  height: auto;
  gap: 4px;

  margin-top: 5px;

  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;

  color: #373d3f;
}

.more-span:hover + .additional-jobs {
  display: block;
}

.hist-rows {
  position: relative;
}

/* for history mobile view  */

.time-entry-header-wrapper {
  border-radius: 4px 4px 0px 0px;
  background: var(--ui-design-primary, #3b98d1);
  padding: 14px 12px;
}

.time-entry-div {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.history-time-entry-div {
  border: 1px solid #e7eaee;
  border-radius: 4px;
  padding: 16px, 12px, 16px, 12px;
}

.sub-entry-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #e7eaee;
  background-color: #f2f8fe;
  width: 100%;
  height: 204px;
  border-radius: 4px;
  margin-bottom: 0rem;
  gap: 16px;
}

.sub-entries-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 1px solid #e7eaee;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px, 12px, 16px, 12px;

  border-top: none;
}

.remove-border {
  border-bottom: none;
}

.tt-height {
  height: calc(100vh - 360px);
  overflow: auto;
}

.daily-log-tt-height {
  /* height: calc(100vh - 320px);
  overflow: auto; */
}

.tt-height-custom {
  height: calc(100vh - 330px);
  overflow: auto;
}

.tag-wrapper {
  background: #fff;
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  left: 0;
  bottom: -100px;
  padding: 18px;
  border-radius: 0px 0px 8px 8px;
}

.add-user-listing-wrapper {
  max-height: 240px;
  overflow: auto;
}

.core-tt-select-job {
  display: flex;
  gap: 8px;
  border-radius: 4px;
  background: #3b98d1;
  padding: 8px 10px;
  color: #f5f5f5;
}

.expanded-wrapper {
  border-left: 1px solid #e7eaee;
  border-right: 1px solid #e7eaee;
  padding: 20px 24px !important;
}

.expanded-row-gap {
  width: calc(100vw - 410px);
}

.expanded-inner-wrapper {
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #e7eaee;
  background: #f2f8fe;
}

.tt-desc-input {
  color: #3b98d1;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  border: 1px solid #3b98d1;
}

.search-input {
  padding: 0.5rem;
  width: 20vw;
  border-radius: 4px;
  border: 1px solid #e7eaee;
}

.search-bg {
  padding: 0.5rem;
  background-color: #f4f4f4;
}

.time-input-mobile {
  border: none;
  background-color: transparent;
  padding: 0px !important;
  width: auto !important;
  max-width: 40px;
  text-decoration-line: underline !important;
  color: #3b98d1 !important;
}

.time-input {
  max-width: 80px;
  padding: 10.533px 6.285px 11.467px 21.715px !important;
}

.time-entry-field {
  max-width: 50px !important;
  padding: 10.533px 5.715px 11.467px 15.715px !important;
}

.job-listing-height {
  max-height: 200px;
  overflow: auto;
}

.absolute-badge {
  right: -30px;
  bottom: 2px;
  position: absolute;
  z-index: 999999;
}

.custom-badge {
  border-radius: 30px;
  background: #e9e9e9;
  padding: 0px 6px;
}

.custom-name-badge {
  border-radius: 36px;
  background: #f4f4f4;
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.tooltip-wrapper {
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  z-index: 9999 !important;
  min-width: 120px !important;
}

.tooltip-entries {
  max-height: 200px;
  overflow: auto;
}

/* Pagination Style */
.pag-item-wrapper {
  padding: 5px 12px !important;
  background: #e8e8e8;
  padding: 4px;
}

.sl-page {
  background-color: #3b98d1 !important;
  color: #fff !important;
}

.page-couter {
  border-radius: 4px;
  border: 1px solid #e0e7ed;
  background: var(--White, #fff);
  padding: 4px;
}

.dropdown {
  position: unset !important;
}

.mbl-log-table {
  border-radius: 4px;
  border: 1px solid #e7eaee;
  background: #ececec;
  padding: 16px 12px 12px 12px;
  display: flex;
  padding: 16px 12px 12px 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
}

.user-detail-wrapper {
  width: 100%;
  border-radius: 4px;
  background: #fff;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.worker-listing-modal .modal-content {
  max-width: 480px !important;
}

/* Override react data table */
.rdt_TableHeadRow {
  border: none !important;
  display: flex !important;
  justify-content: space-between !important;
}

.rdt_TableRow {
  border: 1px solid #e7eaee;
  padding: 16px 0px !important;
}

.rdt_TableCol {
  color: #373d3f;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.custom-table > div {
  overflow: visible;
}

.custom-table-log .rdt_Table {
  padding: 10px !important;
  max-height: calc(100vh - 345px);
  overflow: auto;
}

.rdt_TableCell > div {
  width: 100%;
}

.profile-cell-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .tt-height {
    max-height: calc(100vh - 410px);
  }

  .time-entry-field {
    max-width: 35px !important;
    padding: 2.533px 2.715px 2.467px 2.715px !important;
  }

  .tt-height-custom {
    max-height: calc(100vh - 200px);
  }

  .custom-table-log-mbl {
    max-height: calc(100vh - 385px);
    overflow: auto;
  }

  .search-input {
    width: 100%;
  }

  .additional-jobs {
    right: -2px;
  }
}

/* text-label-primary */
