@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.clocker-wrapper {
    background-color: #FFF;
    padding: 12px 16px 12px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.track-wrapper {
    background: #FFF;
    box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 40px;
    height: 136px;
}

.info-text>span {
    color: #555F61;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    min-width: 50px;
}

.info-text>p {
    color: #373D3F;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0px;
    min-width: 50px;
    text-decoration-line: underline;
}

.secondary-container {
    padding: 24px 40px !important;
    background-color: #F5F5F5;
}

.dashboard-container {
    padding: 24px 40px !important;
    background-color: #F5F5F5;
    height: calc(100% - 188px);
}

.dashboard-container-tl {
    padding: 24px 40px !important;
    background-color: #F5F5F5;
    height: calc(100% - 190px);
}

.disabled-input-wrapper {
    background-color: #F4F4F4;
    font-family: Lato !important;
    height: 44px;
    width: 110px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.desc-input-field {
    display: flex;
    width: 353px;
    height: 44px;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #E7EAEE;
    background: #FFF;
}

.flex-alignment {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-select {
    display: flex;
    height: 44px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #3B98D1;
    color: #3B98D1;
    background: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.filter-input-field {
    height: 44px;
    padding: 8px;
    border-radius: 4px;
    background: #F4F4F4;
    border: none !important;
}

.filter-input-field:focus {
    border: none !important;
}

.jobs-listing,
.jobs-listing a {
    color: #3B98D1;
}

.jobs-listing span {
    padding: 0.5rem;
}

.jobs-listing span:hover,
.jobs-listing a:hover {
    background-color: #F4F4F4 !important;
}

.job-listing-modal .modal-content {
    max-width: 300px !important;
}

.job-listing-modal .modal-dialog {
    justify-content: center !important;
}

.job-listing-modal .jobs-listing {
    max-height: 300px !important;
    overflow: auto;
}

.table-wrapper {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(21, 21, 21, 0.07);
}

.table-wrapper-custom {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(21, 21, 21, 0.07);
}

.table-header {
    border-radius: 4px 4px 0px 0px;
    background: #3B98D1;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
}

.tt-table>tr {
    border: 1px solid;
    border-collapse: collapse;
}

.tt-mobile-wrapper {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(21, 21, 21, 0.07);
    padding: 20px 16px 16px 16px;
}

.h-100-330 {
    height: calc(100vh - 330px);
}

/* histoy tab  */

.clocker-wrapper .MuiFormControl-root {
    width: auto !important;
}

.history-heading .MuiFormControl-root {
    width: 180px !important;
}


@media only screen and (max-width: 850px) and (min-width: 768px) {
    .desc-input-field {
        width: 250px;
    }
}


@media only screen and (max-width: 768px) {
    .desc-input-field {
        max-width: 353px !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .clocker-wrapper {
        display: block;
    }

    .secondary-container {
        padding: 0px !important;
        background-color: #FFF;
    }

    .dashboard-container {
        height: calc(100% - 260px);
    }

    .dashboard-container-tl {
        height: calc(100% - 202px);
    }

    .table-header {
        display: block;
        line-height: 40px;
    }

    .pre-table-haeading>h4 {
        margin-bottom: 0;
    }
}