.expanded-row {
  background-color: #f2f8fe;
  padding: 15px 30px;
  margin: 0px 5px;
}
.sub-heading-text {
  font-size: 14px;
  color: #555f61;
}
.data-font {
  font-size: 20px;
  color: #373d3f;
}
.table-wrapper {
  min-height: 80vh;
}
