.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 70vw;
  padding: 30px 0px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-container img {
  border-radius: 0.25rem;
}
.body {
  background-color: #d6d1d1;
}

.primary-button {
  padding: 0.625rem 1.5rem;
  border-radius: 0.25rem;
  background: #3b98d1;
  border: none;
  color: white;
}

.primary-button-outline {
  border-radius: 4px;
  padding: 0.625rem 1.5rem;
  border: 1px solid #3b98d1;
  background: #fff;
  color: #3b98d1;
  font-family: Lato;
  font-size: 14px;
}
.make-modal {
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  color: #555f61;
  padding: 0 10px;
}

@media (max-width: 380px) {
  .primary-button {
    padding: 0.625rem 0.7rem;
  }

  .primary-button-outline {
    padding: 0.625rem 0.7rem;
  }
}
