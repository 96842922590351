.form-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 0.75em;
}
.required-color {
  color: #eb001b !important;
}
.text-color {
  color: #555f61;
}
select {
  color: #555f61;
  height: 50px;
}
option {
  color: #555f61;
}
.camera-button {
  background-color: #3b98d1;
  color: white;
  padding: 7px 0px;
  width: 170px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remove-button {
  background-color: #e7eaee;
  color: #3b98d1;
  border: 1px solid #3b98d1;
  padding: 5px 30px;
  border-radius: 5px;
  cursor: pointer;
}
.retake-button {
  background-color: #d5ebf8;
  color: #3b98d1;
  border: 1px solid #3b98d1;
  padding: 5px 30px;
  border-radius: 5px;
  cursor: pointer;
}
.invalid-input {
  border: 1px solid tomato !important;
}
.card-container {
  background-color: #ffffff;
  margin: 2% 1%;
  padding: 4% 1%;
  border-radius: 5px;
}
.image-container {
  border: 1px solid #e7eaee;
}
.sig-text {
  color: #373d3f;
  font-weight: 600;
  font-size: 20px;
}
.add-sig-text {
  color: #3b98d1;
  font-weight: 600;
  font-size: 15px;
}
.sig-container {
}
.btn-container {
  background-color: #ffffff;
  padding: 4% 1%;
  width: 112%;
  position: relative;
  bottom: -20px;
  left: -20px;
}
.signature-canvas-container {
  position: relative;
}
.signature-canvas {
  border: 1px solid #dadedf;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
