.top-section{
    display: flex !important;
    justify-content: space-between !important;
    margin: 0.5% 0%;
    flex-wrap: wrap !important;
}

.left{
  font-size: 20px;
  color: #373D3F;  
  font-weight: 600;
}
.right{
    display: flex;
    justify-content: space-between !important;
    gap: 20px;
    flex-wrap: wrap;
}

.stat{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.title-text{
    font-size: 12px;
    color: #757575;
}

.title-value{
    font-size: 16px;
    color: #373D3F;
}

