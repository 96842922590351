.log-hours-wrapper {
    border-radius: 4px;
    background: #FFF;
    padding: 94px 107px;
    max-width: 580px;
    max-height: 494px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

@media only screen and (max-width: 768px) {
    .log-hours-wrapper {
        padding: 45px 23px 61px 24px;
        margin-top: 24px;
    }
}