@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.bg-login-img {
    background-image: url('../images/login-side.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-cl {
    background-color: #F5F5F5;
}

.login-card {
    display: flex;
    padding: 100px 78px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #FFF;
}

.login-card-header-text {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
}

.login-card-body-text {
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #555F61;
    margin-bottom: 48px;
}

.btn-google {
    color: #373D3F !important;
    background-color: #F4FAFF !important;
}

.btn-fb {
    color: #F5F5F5 !important;
    background-color: #475993 !important;
}

.login-btn {
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    font-family: Lato !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}

@media only screen and (max-width: 576px) {
    /* For mobile phones: */
    .bg-login-img-sm {
        background-image: url('../images/login-img-sm.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .bg-login-img {
        display: none !important;
    }

    .login-card {
        padding: 62px 16px !important;
    }
}

@media only screen and (max-width: 992px) {
    .login-card {
        padding: 70px 30px !important;
    }
}