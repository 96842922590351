@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.header {
  background-color: #3b98d1;
  padding: 18px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: relative;
}

.header-main-txt {
  font-family: Rams;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.96px;
}

.header-secondary-txt {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.nav-link {
  text-decoration: none !important;
  color: #fff !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.active-bar {
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  height: 4px;
  bottom: -20px;
  width: 100%;
  z-index: 99999;
}

.profile-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.mbl-navs-wrapper {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 16px;
  z-index: 999;
  width: 100%;
  color: #373d3f !important;
}

.mbl-navs-wrapper > div {
  padding: 13px 0px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.profile-bar {
  top: 60px !important;
  padding: 0px 16px !important;
}

.link-text {
  text-decoration: none !important;
  color: black !important ;
}

.notif-head {
  background-color: #3b98d1 !important;
}

.notif .dropdown-menu {
  --bs-dropdown-padding-y: 0 !important;
  width: 25rem;
}

.wrap-break {
  white-space: initial !important;
}

.unread-notif {
  background-color: #f1f1f1 !important;
}

.dropdown-toggle::after {
  content: none !important;
}

.count-unseen {
  background-color: #e64b18;
  top: 0;
  color: white;
  border-radius: 50%;
  padding: 1px 8px;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 18px 24px;
  }
}
