.nav-link{
    text-decoration: none;
}

.custom-container{
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    height: 92%;
}

.profile-container{
    background-color: #ffffff;
    margin: 2% 1%;
    padding: 1% 1%;
    border-radius: 5px;
}

.profile-heading{
    font-size: 16px;
    font-weight: 600;
    color: #373D3F;
    padding: 1% 0%;
}

.detail-container{
    display: flex;
    justify-content: space-between;
    padding: 0% 1%;
    flex-wrap: wrap;
    }

.user-image{
    width: 253px;
    height: 234px;
    border-radius: 4px;
    text-align: center;
    margin: 1% 0%;
}

.user-detail{
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgrey;
    flex: 2;
    padding: 1% 1%;
    margin: 0% 1%;
    border-radius: 4px;
    flex-wrap: wrap;
}


.role{
   font-size: 14px;
   font-weight: 500;
   color: #165e71; 
}

.user-left{
    flex: 1;
}

.user-right{
    flex: 2;
}

.user-data{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3% 0%;
}

.text-title{
    font-size: 14px;
    font-weight: 400;
    color: #555f61;
}

.text-value{
    font-size: 14px;
    font-weight: 500;
    color: black;
 }

.edit-btn{
    background-color: white;
    color: #3B98D1;
    font-size: 14px;
    border: none;
    float: right;
}

.certificate-container{
    padding: 0% 1%;
    border: 1px solid lightgrey;
    margin: 2% 1%;
    border-radius: 5px;
    width: 97%;
}


.certificates{
    display: flex;
    justify-content: start;
    padding: 0% 1% 3% 1%;
    flex-wrap: wrap;
    gap: 10px;
}

.certificate{
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #f4f4f4 ;
    padding: 0% 2% 0% 0%;
    gap: 15px;
    flex-wrap: wrap;
}

.certificate-iamge{
    width: 169px;
    height: 189px;
}

.certificate-detail{
    padding: 2% 0%;
}

.certificate-heading{
    font-size: 16px;
    padding: 1% 1%;
}

.download-btn{
    background-color: #f4f4f4;
    color: #3B98D1;
    font-size: 14px;
    border: none;
}

@media (min-width: 400px) {
    .download-btn {
        margin-top: 45%;
        float: right;
    }
}

.edit-detail{
    border: 1px solid lightgrey;
    padding: 1% 1% 5% 1%;
    margin: 0% 1% 5% 1%;
    border-radius: 4px;
    
}

.top-section-c{
    display: flex !important;
    justify-content: start !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    gap: 20px;
}

.bottom-section{
    margin: 3% 0%;
    width: 20%;
}

.user-image-2{
    width: 190px;
    height: 162px;
}

.actions{
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.btn-change{
    background-color:#3B98D1 !important;
    border: none;
}

.btn-remove,.btn-remove:hover,.btn-remove:active{
    background-color: white !important;
    color: red !important;
}

.png-size{
    color: #555F61;
    font-size: 12px !important;
    margin-top: 2%;
}
.actions-bottom{
    margin: 7% 0%;
    display: flex;
    justify-content: start;
    gap: 10px;
}

.btn-cancel,.btn-cancel:hover,.btn-cancel:active{
    border: 1px solid black;
}

@media (max-width: 1200px) {
    .bottom-section {
        width: 30%;
    }
}

@media (max-width: 992px) {
    .bottom-section {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .bottom-section {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .bottom-section {
        width: 60%;
    }
    .top-section-c{
        justify-content: center !important;
        align-items: center;
    }

    .user-image {
        width: 100%;
        height: auto;
        margin: auto auto 1% 15% !important;

    }
}

@media (max-width: 480px) {
    .bottom-section {
        width: 70%;
    }

    .user-image {
        width: 100%;
        height: auto;
        margin: auto auto 1% auto !important;

    }
}

@media (max-width: 360px) {
    .bottom-section {
        width: 80%;
    }
}

@media (max-width: 280px) {
    .bottom-section {
        width: 90%;
    }
}

@media (max-width: 200px) {
    .bottom-section {
        width: 100%;
    }
}
