.card-container {
  background-color: #ffffff;
  margin: 2% 1%;
  padding: 4% 1%;
  border-radius: 5px;
}
.heading-font {
  font-weight: 600;
  font-size: 20px;
}
.role-font {
  font-size: 14px;
  color: #165e71;
  font-weight: 500;
}
.main-temp {
  color: #ffffff;
  font-size: 32px;
  position: absolute;
  top: 0px;
  right: 8px;
  font-weight: 300;
}
.area {
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  top: 15px;
  left: 8px;
  font-weight: 600;
}
.peak-temp {
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-weight: 500;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader-custom:empty {
  position: fixed;
  top: calc(50% - 2em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  z-index: 353455555545;
}

.round-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.text-black {
  color: black;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content p {
  margin-bottom: 20px;
}
.weather-image {
  width: 187px;
  height: 90px;
}
.truck-image {
  height: 90px;
  width: 96px;
}

@media (max-width: 380px) {
  .weather-image {
    width: 150px;
  }
  .truck-image {
    width: 80px;
  }
}
