.notif-header {
  padding: 1.5rem;
}

@media (max-width: 780px) {
  .notif-header {
    padding: 1rem;
  }
}

@media (max-width: 525px) {
  .notif-header {
    padding: 0.5rem;
  }
}


.btn-mark{
  background-color: #3b98d1;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  margin-right: 10px;
  font-size: 16px;
}