.profile-dropdown-icon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: auto 0;
}

.view-all {
  color: #3b98d1 !important;
}

.small-icon {
  width: 40px;
  height: 40px;
}

.user-profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}